<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="100px" size="mini">
                <div class="transition-box">
                    <el-form-item label="学员编号" prop="studentId">
                        <el-input v-model="params.studentId" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员姓名" prop="studentName">
                        <el-input v-model="params.studentName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员备注" prop="remarks">
                        <el-input v-model="params.remarks" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员类型" prop="studentType">
                        <el-select v-model="params.studentType">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="item in studentTypeList" :key="item.id"
                                       :value="item.dictValue" :label="item.dictLabel"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="下单手机号" prop="mobile">
                        <el-input v-model="params.mobile" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称" prop="goodsName">
                        <el-input v-model="params.goodsName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="下单渠道" prop="orderChannel">
                        <el-select v-model="params.orderChannel">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="item in orderChannelList" :key="item.id"
                                       :value="item.dictValue" :label="item.dictLabel"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分配状态" prop="allotStatus">
                        <el-select v-model="params.allotStatus">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未分配" :value="false"></el-option>
                            <el-option label="已分配" :value="true"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="购买时间" prop="buyTime">
                        <el-date-picker
                                v-model="buyTimeList"
                                type="daterange"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </div>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button-group>
                    <el-button type="primary" size="mini" v-permission="'waiter:student:allot'" @click="allotShow">分配</el-button>
                    <el-button type="primary" size="mini" v-permission="'waiter:student:uploadDetail'" @click="upload">导入</el-button>
                    <el-button type="primary" size="mini" v-permission="'waiter:student:delete'" @click="handleDelete">删除</el-button>
                    <el-button type="primary" size="mini" v-permission="'waiter:student:worker'" >
                        <a style="text-decoration:none;color:inherit;"
                           href="https://y0vlq7dl91w.feishu.cn/wiki/Lf70wfbv1iooAPk1Z8hch6pwnnb"
                           target="_blank">排班信息</a>
                    </el-button>
                    <el-button type="info" size="mini" v-permission="'waiter:student:dict'" @click="showDict">管理字典</el-button>
                </el-button-group>
            </el-col>
            <el-col :span="6">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading"
                  @selection-change="handleSelectionChange">
            <el-table-column align="center" type="selection" prop="studentId" width="50"></el-table-column>
            <el-table-column align="center" label="主键" prop="studentId"></el-table-column>
            <el-table-column align="center" label="学员姓名" prop="studentName"></el-table-column>
            <el-table-column align="center" label="首次沟通时间" prop="firstTime"></el-table-column>
            <el-table-column align="center" label="学员备注" prop="remarks"></el-table-column>
<!--            <el-table-column align="center" label="学员类型" prop="studentType">-->
<!--                <template v-slot="scope">-->
<!--                    <span v-for="item in studentTypeList" :key="item.id"-->
<!--                          v-if="scope.row.studentType === item.dictValue">{{ item.dictLabel }}</span>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column align="center" label="成单销售" prop="orderSalesName"></el-table-column>-->
<!--            <el-table-column align="center" label="成单类型" prop="orderType"></el-table-column>-->
<!--            <el-table-column align="center" label="商品名称" prop="goodsName"></el-table-column>-->
<!--            <el-table-column align="center" label="成单价格" prop="orderPrice"></el-table-column>-->
            <el-table-column align="center" label="下单手机号" prop="mobile">
                <template v-slot="scope">
                    <span>{{ scope.row.mobile }}
                        <el-button type="text" v-if="!scope.row.showMobile" @click="handleView(scope.row)"><i
                                class="el-icon-view"></i></el-button>
                    </span>
                </template>
            </el-table-column>
<!--            <el-table-column align="center" label="下单渠道" prop="orderChannel">-->
<!--                <template v-slot="scope">-->
<!--                    <span v-for="item in orderChannelList" :key="item.id"-->
<!--                          v-if="scope.row.orderChannel === item.dictValue">{{ item.dictLabel }}</span>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column align="center" label="购买时间" prop="buyTime"></el-table-column>-->
<!--            <el-table-column align="center" label="订单编号" prop="orderNo"></el-table-column>-->
            <el-table-column align="center" label="服务号" prop="servicesId">
                <template v-slot="scope">
                    <span v-for="item in serviceList" :key="item.servicesId"
                          v-if="item.servicesId === scope.row.servicesId">{{ item.name }}</span>
                </template>
            </el-table-column>
<!--            <el-table-column align="center" label="状态" prop="status"></el-table-column>-->
            <el-table-column align="center" label="操作" fixed="right">
                <template v-slot="scope">
                    <el-button type="text" size="mini" icon="el-icon-user" v-permission="'waiter:student:info'" @click="info(scope.row)">详情</el-button>
                    <el-button type="text" size="mini" icon="el-icon-s-promotion" v-permission="'waiter:student:allot'" @click="allotShow(scope.row)">分配</el-button>
                    <el-button type="text" size="mini" icon="el-icon-s-promotion" v-permission="'waiter:student:delete'" @click="handleDelete(scope.row)">
                      删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page.sync="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size.sync="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <el-drawer
                title="详情"
                :visible.sync="infoDrawer"
                v-if="infoDrawer"
                size="50%"
                style="overflow: auto;"
                :append-to-body="true"
                direction="rtl">
            <el-row style="text-align: center">
                <el-col :span="2">
                    <div class="grid-content"></div>
                </el-col>
                <el-col :span="7">主键：{{ studentInfo.studentId }}</el-col>
                <el-col :span="7">姓名：{{ studentInfo.studentName }}</el-col>
                <!--                <el-col :span="7">性别：{{studentInfo.sex}}</el-col>-->
                <el-col :span="1">
                    <div class="grid-content"></div>
                </el-col>
            </el-row>
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="基本信息" name="info">
                    <WaiterStudentDetailRecord :student-info="studentInfo"
                                               v-if="activeName === 'info'"></WaiterStudentDetailRecord>
                </el-tab-pane>
                <el-tab-pane label="回访记录" name="access">
                    <WaiterStudentAccessRecord :student-id="studentInfo.studentId"
                                               v-if="'access' === activeName"></WaiterStudentAccessRecord>
                </el-tab-pane>
                <el-tab-pane label="售前操作记录" name="sales">
                    <WaiterStudentSaleRecord :student-id="studentInfo.studentId"
                                             v-if="'sales' === activeName"></WaiterStudentSaleRecord>
                </el-tab-pane>
                <el-tab-pane label="服务记录" name="service">
                    <WaiterStudentServiceRecord  :record-pro="studentInfo" v-if="'service' === activeName"></WaiterStudentServiceRecord>
                </el-tab-pane>
            </el-tabs>
        </el-drawer>

        <!--   分配     -->
        <AllotStudent :allot-pro="allotPro" :student-list="studentList" title="分配学员"
                      :callback="findPage"></AllotStudent>

        <!--    导入学员    -->
        <UploadStudent :student-pro="uploadPro" :callback="findPage"></UploadStudent>

        <dict-show :dict="dictObj" v-if="dictObj.show"></dict-show>
    </div>
</template>

<script>
import * as studentApi from "@/api/waiter/waiterStudent"
import * as serviceApi from "@/api/waiter/waiterServices"
import * as accountApi from "@/api/system/account"
import * as pondApi from "@/api/clue/cluePond"
import * as dictionaryApi from "@/api/system/dictionary"
import DictShow from "@/components/DictShow.vue";
import {removeStudent} from "@/api/waiter/waiterStudent";

const WaiterStudentAccessRecord = () => import("@/views/waiter/detail/WaiterStudentAccessRecord");
const WaiterStudentDetailRecord = () => import("@/views/waiter/detail/WaiterStudentDetailRecord");
const WaiterStudentSaleRecord = () => import("@/views/waiter/detail/WaiterStudentSaleRecord");
const WaiterStudentServiceRecord = () => import("@/views/waiter/detail/WaiterStudentServiceRecord");
const AllotStudent = () => import("@/views/waiter/detail/AllotStudent");
const UploadStudent = () => import("@/views/waiter/detail/UploadStudent");

export default {
    name: "WaiterStudent",
    components: {
        DictShow,
        WaiterStudentAccessRecord,
        WaiterStudentDetailRecord,
        WaiterStudentSaleRecord,
        WaiterStudentServiceRecord,
        AllotStudent,
        UploadStudent
    },
    data() {
        return {
            uploadPro: {
                show: false,
            },
            allotPro: {
                show: false,
            },
            studentList: [],
            allotFromSync: false,
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            dataFrom: {
                servicesId: null,
                orderType: 1,
            },
            dataFromRules: {
                mobile: [{required: true, message: '请输入手机号', trigger: 'blur'}],
                orderType: [{required: true, message: '请选择成单类型', trigger: 'blur'}],
                studentType: [{required: true, message: '请选择学员类型', trigger: 'blur'}],
                // orderSalesName: [{required: true, message: '请选择成单销售', trigger: 'blur'}],
                // orderPrice: [{required: true, message: '成单价格', trigger: 'blur'}],
                goodsName: [{required: true, message: '商品名称', trigger: 'blur'}],
                orderChannel: [{required: true, message: '下单渠道', trigger: 'blur'}],
                orderNo: [{required: true, message: '订单编号', trigger: 'blur'}],
                buyTime: [{required: true, message: '购买时间', trigger: 'blur'}],
                servicesId: [{required: true, message: '班主任服务号', trigger: 'blur'}],
            },
            dataFromAdd: true,
            params: {
                current: 1,
                size: 10
            },
            loading: false,
            dataList: [],
            total: 0,
            buyTimeList: [],
            idList: [],
            serviceList: [],
            accountList: [],
            pondList: [],
            studentTypeList: [],
            orderChannelList: [],
            infoDrawer: false,
            studentInfo: {},
            activeName: 'info',
            dictObj: {
                show: false
            },
        }
    },
    mounted() {
        this.resetQuery()
        this.findPage()
        this.findService()
        this.findAccount()
        this.findPond()
        this.findStudentType()
        this.findOrderChannel()
    },
    methods: {
        showDict() {
            this.dictObj.codes = 'orderChannel,studentType'
            this.dictObj.show = true
        },
        handleView(row) {
            studentApi.viewMobile(row.studentId).then(res => {
                if (res.success) {
                    row.mobile = res.data
                    row.showMobile = true
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        upload() {
            this.uploadPro = {
                show: true,
                title: "学员基础信息",
                action: "/api/manage/waiter/student/uploadDetail",
                template: "/api/manage/waiter/student/downloadTemplateDetail"
            }
        },
      handleDelete(row){
        let ids = (row.studentId ? [row.studentId] : null) || this.idList
        if(ids.length === 0){
          this.$message.error("请选择数据!")
          return
        }
        this.$confirm("是否确认删除学员?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: 'warning'
        }).then(() => {
          studentApi.removeStudent(ids).then(res=>{
            if(res.success){
              this.$message.success(res.msg)
              this.findPage()
            }else{
              this.$message.error(res.msg)
            }
          })
        })
      },
        allotShow(row) {
            this.studentList = (row.studentId ? [row] : null) || this.studentList
            this.allotPro.show = true
        },
        handleSelectionChange(val) {
            this.idList = val.map(item => item.studentId)
            this.studentList = val
        },
        info(row) {
            this.infoDrawer = true
            this.studentInfo = row
        },
        findStudentType() {
            dictionaryApi.findChildrenByCode('studentType').then(res => {
                if (res.success) {
                    this.studentTypeList = res.data
                }
            })
        },
        findOrderChannel() {
            dictionaryApi.findChildrenByCode('orderChannel').then(res => {
                if (res.success) {
                    this.orderChannelList = res.data
                }
            })
        },
        findPond() {
            pondApi.listCluePond().then(res => {
                if (res.success) {
                    this.pondList = res.data
                }
            })
        },
        findAccount() {
            accountApi.list().then(res => {
                if (res.success) {
                    this.accountList = res.data
                }
            })
        },
        findService() {
            serviceApi.findList().then(res => {
                if (res.success) {
                    this.serviceList = res.data
                }
            })
        },
        /**
         * 重置
         */
        resetQuery() {
            this.params = {
                current: 1,
                size: 10,
                status: 0,
                // allotStatus:false,
            }
            this.buyTimeList = []
        },
        /**
         * 点击搜索
         */
        doSearch() {
            this.params.current = 1
            this.findPage()
        },
        /**
         * 分页查询数据
         */
        findPage() {
            this.loading = true
            if (this.buyTimeList) {
                this.params.buyTimeStart = this.buyTimeList[0]
                this.params.buyTimeEnd = this.buyTimeList[1]
            }
            studentApi.findPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.el-pagination {
  bottom: 10px;
  position: fixed;
}

/deep/ .el-drawer {
  overflow: auto;
}
</style>